import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import OOI from './ooi'
import OOISdkHandlersProvider from './ooiSdkHandlersProvider'
import { CssFetcherSymbol, ICssFetcher, LifeCycle, WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import { OOISsrManagerSymbol, ReactLoaderForOOISymbol } from './symbols'
import { ooiComponentsRegistrar } from './ooiComponentsRegistrar'
import { ComponentsRegistrarSymbol } from '@wix/thunderbolt-components-loader'
import { WarmupDataEnricherSymbol } from 'feature-warmup-data'
import OOIPostSsrPropsEnricher from './ssr/ooiPostSsrPropsEnricher'
import OOISsrManager from './ssr/ooiSsrManager'
import { OoiCssFetcher } from './ooiCssFetcher'
import { ComponentWillMountSymbol } from 'feature-components'
import { ooiComponentWillMount } from './ooiComponentWillMount'
import { ooiLoadComponentsPageWillMount } from './ooiLoadComponentsPageWillMount'
import ReporterFactory, { Reporter, OOIReporterSymbol } from './reporting'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler, LifeCycle.PageDidMountHandler).to(OOI)
	bind(LifeCycle.PageWillMountHandler).to(ooiLoadComponentsPageWillMount)
	bind(WixCodeSdkHandlersProviderSym).to(OOISdkHandlersProvider)
}

export const site: ContainerModuleLoader = (bind) => {
	bind(ComponentsRegistrarSymbol).to(ooiComponentsRegistrar)
	bind<ICssFetcher>(CssFetcherSymbol).to(OoiCssFetcher)
	bind<Reporter>(OOIReporterSymbol).to(ReporterFactory)

	if (process.env.browser) {
		bind(ReactLoaderForOOISymbol).to(require('./client/componentsLoaderClient').default)
		bind(LifeCycle.AppWillRenderFirstPageHandler, LifeCycle.AppDidMountHandler).to(OOIPostSsrPropsEnricher)
	} else {
		bind(ReactLoaderForOOISymbol).to(require('./ssr/componentsLoaderSSR').default)
		bind(OOISsrManagerSymbol, WarmupDataEnricherSymbol).to(OOISsrManager)
	}
}

export const editor: ContainerModuleLoader = (bind) => {
	bind<Reporter>(OOIReporterSymbol).to(ReporterFactory)
	bind(ReactLoaderForOOISymbol).to(require('./client/componentsLoaderClient').default)
	bind(ComponentsRegistrarSymbol).to(ooiComponentsRegistrar)
	bind(ComponentWillMountSymbol).to(ooiComponentWillMount)
	bind(WixCodeSdkHandlersProviderSym).to(OOISdkHandlersProvider)
	// bind(LifeCycle.PageWillMountHandler, LifeCycle.PageDidMountHandler).to(OOI)
	// bind<ICssFetcher>(CssFetcherSymbol).to(OoiCssFetcher)
}

export * from './types'
export * from './symbols'
